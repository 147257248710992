class DashboardService {
  constructor() {}
  resources = () => ({
    cargarTotales: {
      //uri: `/v1/presupuesto/total-real-detallado`,
      uri: `/v1/presupuesto/total-aprobado-x-sede`,
      method: ['get']
    },
    cargarTiposPresupuesto: {
      uri: `/v1/presupuesto/listar-formato`,
      method: ['get']
    },
    cargarSedes: {
      uri: `/v1/presupuesto/listar-empresa-sede`,
      method: ['get']
    },
    cargarSubAreas: {
      uri: `/v1/presupuesto/opex/listar-subarea-ppto`,
      method: ['get']
    },
    cargarCuentasMayores: {
      uri: `/v1/presupuesto/listar-cuenta-mayor`,
      method: ['get']
    },
    cargarPeriodos: {
      uri: `/v1/presupuesto/listar-periodo-inicio-fin`,
      method: ['get']
    },
    cargarGraficoPresentadoVsAprobadoXPeriodo: {
      uri: `/v1/presupuesto/reporte-presentado-aprobado-periodo`,
      method: ['get']
    },
    cargarGraficoPresupuestoVsRealXPeriodo: {
      uri: `/v1/presupuesto/reporte-presupuesto-vs-real-periodo`,
      method: ['get']
    },
    cargarGraficoEjecucionPresupuestoVsRealXArea: {
      uri: `/v1/presupuesto/reporte-real-vs-presupuesto-porcentaje-x-area`,
      method: ['get']
    },
    cargarGraficoPresentadoVsAprobadoXArea: {
      uri: `/v1/presupuesto/dashboard-presentado-aprobado-x-area-ppto`,
      method: ['get']
    },
    cargarGraficoPresentadoVsAprobadoXCuentaMayor: {
      uri: `/v1/presupuesto/reporte-presentado-aprobado-cuenta-mayor`,
      method: ['get']
    },
    cargarGraficoPresupuestoVsRealXCuentaMayor: {
      uri: `/v1/presupuesto/reporte-presupuesto-vs-real-cuenta-mayor`,
      method: ['get']
    },
    cargarDatosDetalleRealXAreaYCeco: {
      uri: `/v1/presupuesto/reporte-real-por-area-centrocosto`,
      method: ['get']
    },
    cargarDatosPresupuestoVsRealXAreaXCecoYCuentaMayor: {
      uri: `/v1/presupuesto/reporte-real-vs-saldo-por-centrocosto-y-cuentamayor`,
      method: ['get']
    }
  });
}

export default DashboardService;
