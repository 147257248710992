<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row v-auth-acl="'gh-pres-dashpres-read'">
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-text>
            <TotalesPresupuesto
              :panelTotales="panelTotales"
              :totalesPorSede="totalesPorSede"
              :totalesComparativa="totalesComparativa"
              @expand="panelTotales = $event"
            ></TotalesPresupuesto>
            <HeaderFilter
              :panelTotales="panelTotales"
              :selectedTipoPresupuesto="selectedTipoPresupuesto"
              :selectedSedes="selectedSedes"
              :selectedSubAreas="selectedSubAreas"
              :selectedCuentasMayores="selectedCuentasMayores"
              :periodoInicio="periodoInicio"
              :periodoFin="periodoFin"
              :totalesPorSede="totalesPorSede"
              :totalesComparativa="totalesComparativa"
              :classheaderfilters="classheaderfilters"
              @cargarGraficos="cargarGraficos"
              @openFilter="openFilter"
            ></HeaderFilter>
            <v-card color="grey lighten-5 pa-0" elevation="0">
              <v-card-text class="py-0 px-3">
                <v-row class="pa-0 d-flex justify-end">
                  <v-col cols="12" sm="12" lg="12" class="px-4 py-6">
                    <v-row>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G1PresentadoVsAprobadoXPeriodo
                          :datos="datosPresentadoVsAprobadoXPeriodo"
                        ></G1PresentadoVsAprobadoXPeriodo>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G2PresupuestoVsRealXPeriodo
                          :datos="datosPresupuestoVsRealXPeriodo"
                        ></G2PresupuestoVsRealXPeriodo>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G3EjecucionPresupuestoVsRealXArea
                          :datos="datosEjecucionPresupuestoVsRealXArea"
                        ></G3EjecucionPresupuestoVsRealXArea>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G4PresentadoVsAprobadoXArea
                          :datos="datosPresentadoVsAprobadoXArea"
                        ></G4PresentadoVsAprobadoXArea>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G6PresentadoVsAprobadoXCuentaMayor
                          :datos="datosPresentadoVsAprobadoXCuentaMayor"
                        ></G6PresentadoVsAprobadoXCuentaMayor>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G7PresupuestoVsRealXCuentaMayor
                          :datos="datosPresupuestoVsRealXCuentaMayor"
                        ></G7PresupuestoVsRealXCuentaMayor>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G9DetalleRealXAreaYCeco :datos="datosDetalleRealXAreaYCeco"></G9DetalleRealXAreaYCeco>
                      </v-col>
                      <v-col cols="12" sm="12" lg="12" class="px-3 py-1">
                        <G11PresupuestoVsRealXAreaXCecoYCuentaMayor
                          :datos="datosPresupuestoVsRealXAreaXCecoYCuentaMayor"
                        ></G11PresupuestoVsRealXAreaXCecoYCuentaMayor>
                      </v-col>
                    </v-row>
                  </v-col>
                  <FilterGraficos
                    :panelTotales="panelTotales"
                    :itemsTiposPresupuesto="itemsTiposPresupuesto"
                    :selectedTipoPresupuesto="selectedTipoPresupuesto"
                    :itemsSedes="itemsSedes"
                    :selectedSedes="selectedSedes"
                    :itemsSubAreas="itemsSubAreas"
                    :selectedSubAreas="selectedSubAreas"
                    :itemsCuentasMayores="itemsCuentasMayores"
                    :selectedCuentasMayores="selectedCuentasMayores"
                    :itemsPeriodos="itemsPeriodos"
                    :periodoInicio="periodoInicio"
                    :periodoFin="periodoFin"
                    :showFilter="showFilter"
                    :classfilters="classfilters"
                    @closeFilter="closeFilter"
                    @filtrar="filtrar"
                  ></FilterGraficos>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import DashboardService from '../services/DashboardService';
//import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'Dashboard',
  data: () => ({
    datos: {},
    chip1: false,
    itemsTiposPresupuesto: [],
    selectedTipoPresupuesto: null,
    itemsSedes: [],
    selectedSedes: [],
    itemsSubAreas: [],
    selectedSubAreas: [],
    itemsCuentasMayores: [],
    selectedCuentasMayores: [],
    itemsPeriodos: [],
    periodoInicio: null,
    periodoFin: null,
    showFilter: false,
    datosPresentadoVsAprobadoXPeriodo: [],
    datosPresupuestoVsRealXPeriodo: [],
    datosEjecucionPresupuestoVsRealXArea: [],
    datosPresentadoVsAprobadoXArea: [],
    datosPresentadoVsAprobadoXCuentaMayor: [],
    datosPresupuestoVsRealXCuentaMayor: [],
    datosDetalleRealXAreaYCeco: [],
    datosPresupuestoVsRealXAreaXCecoYCuentaMayor: [],
    service: null,
    showLoading: false,
    page: {
      title: 'Presupuesto'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'Dashboard',
        disabled: true
      }
    ],
    panelTotales: [],
    totalesPorSede: [],
    totalesComparativa: [],
    classheaderfilters: '',
    classfilters: ''
  }),
  components: {
    TotalesPresupuesto: () => import('../components/TotalesPresupuesto'),
    HeaderFilter: () => import('../components/HeaderFilter'),
    FilterGraficos: () => import('../components/FilterGraficos'),
    G1PresentadoVsAprobadoXPeriodo: () => import('../components/G1PresentadoVsAprobadoXPeriodo'),
    G2PresupuestoVsRealXPeriodo: () => import('../components/G2PresupuestoVsRealXPeriodo'),
    G3EjecucionPresupuestoVsRealXArea: () => import('../components/G3EjecucionPresupuestoVsRealXArea'),
    G4PresentadoVsAprobadoXArea: () => import('../components/G4PresentadoVsAprobadoXArea'),
    G6PresentadoVsAprobadoXCuentaMayor: () => import('../components/G6PresentadoVsAprobadoXCuentaMayor'),
    G7PresupuestoVsRealXCuentaMayor: () => import('../components/G7PresupuestoVsRealXCuentaMayor'),
    G9DetalleRealXAreaYCeco: () => import('../components/G9DetalleRealXAreaYCeco'),
    G11PresupuestoVsRealXAreaXCecoYCuentaMayor: () => import('../components/G11PresupuestoVsRealXAreaXCecoYCuentaMayor')
  },
  computed: {},
  watch: {
    '$store.getters.SidebarExpand'() {
      this.calcularPosFiltro();
    }
  },
  methods: {
    async initialize() {
      this.datos = {};
      this.showLoading = true;
      await this.cargarTotales();
      await this.cargarTiposPresupuesto();
      await this.cargarSedes();
      await this.cargarSubAreas();
      await this.cargarCuentasMayores();
      await this.cargarPeriodos();
      await this.cargarGraficos();
      this.showLoading = false;
    },
    expansionTotales(index) {
      this.headerPorSede[index].activo = !this.headerPorSede[index].activo;
    },
    expansionComparativa(index) {
      this.headerComparativa[index].activo = !this.headerComparativa[index].activo;
    },
    async cargarTotales() {
      const res = await this.service.get().execResource('cargarTotales');
      this.totalesPorSede = res.totalesPorSede;
      this.totalesComparativa = res.totalesComparativa;

      console.log(this.totalesPorSede);
      console.log(this.totalesComparativa);
    },
    async cargarTiposPresupuesto() {
      const res = await this.service.get().execResource('cargarTiposPresupuesto');
      res.forEach((el) => {
        this.itemsTiposPresupuesto.push({ value: el.idleyendaformato, text: el.descripcion });
      });
      this.selectedTipoPresupuesto = this.itemsTiposPresupuesto[0];
    },
    async cargarSedes() {
      const res = await this.service.get().execResource('cargarSedes');
      res.forEach((el) => {
        this.itemsSedes.push({ value: el.idsede, text: el.descripcionsede, ...el });
      });
      this.selectedSedes = this.itemsSedes;
    },
    async cargarSubAreas() {
      const res = await this.service.get().execResource('cargarSubAreas', { idarea: 'GESTHUMANA' });
      res.forEach((el) => {
        this.itemsSubAreas.push({ value: el.idSubarea, text: el.descripcion, ...el });
      });
      this.selectedSubAreas = this.itemsSubAreas;
    },
    async cargarCuentasMayores() {
      const res = await this.service.get().execResource('cargarCuentasMayores');
      res.forEach((el) => {
        this.itemsCuentasMayores.push({ value: el.idcuentamayor, text: el.descripcion, ...el });
      });
      this.selectedCuentasMayores = this.itemsCuentasMayores;
    },
    async cargarPeriodos() {
      const res = await this.service.get().execResource('cargarPeriodos');
      res.Periodos.forEach((el) => {
        this.itemsPeriodos.push({ value: el.periodo, text: el.periodo, ...el });
      });
      if (this.itemsPeriodos.length <= 12) this.periodoInicio = this.itemsPeriodos[0].value;
      else this.periodoInicio = this.itemsPeriodos[this.itemsPeriodos.length - 13].value;
      this.periodoFin = this.itemsPeriodos[this.itemsPeriodos.length - 1].value;
    },
    async openFilter() {
      this.showFilter = !this.showFilter;
    },
    async filtrar(filtro) {
      this.showLoading = true;
      this.showFilter = false;
      this.selectedTipoPresupuesto = filtro.selectedTipoPresupuesto;
      this.selectedSedes = filtro.selectedSedes;
      this.selectedSubAreas = filtro.selectedSubAreas;
      this.periodoInicio = filtro.periodoInicio;
      this.periodoFin = filtro.periodoFin;
      await this.cargarGraficos();
      this.showLoading = false;
    },
    closeFilter() {
      if (this.showFilter) this.showFilter = false;
    },
    async cargarGraficos() {
      this.showLoading = true;
      this.datosPresentadoVsAprobadoXPeriodo = await this.cargarGraficoTipo(
        'cargarGraficoPresentadoVsAprobadoXPeriodo'
      );
      this.datosPresupuestoVsRealXPeriodo = await this.cargarGraficoTipo('cargarGraficoPresupuestoVsRealXPeriodo');
      this.datosEjecucionPresupuestoVsRealXArea = await this.cargarGraficoTipo(
        'cargarGraficoEjecucionPresupuestoVsRealXArea'
      );
      this.datosPresentadoVsAprobadoXArea = await this.cargarGraficoTipo('cargarGraficoPresentadoVsAprobadoXArea');
      this.datosPresentadoVsAprobadoXCuentaMayor = await this.cargarGraficoTipo(
        'cargarGraficoPresentadoVsAprobadoXCuentaMayor'
      );
      this.datosPresupuestoVsRealXCuentaMayor = await this.cargarGraficoTipo(
        'cargarGraficoPresupuestoVsRealXCuentaMayor'
      );
      this.datosDetalleRealXAreaYCeco = await this.cargarGraficoTipo('cargarDatosDetalleRealXAreaYCeco');
      this.datosPresupuestoVsRealXAreaXCecoYCuentaMayor = await this.cargarGraficoTipo(
        'cargarDatosPresupuestoVsRealXAreaXCecoYCuentaMayor'
      );

      this.showLoading = false;
    },
    async cargarGraficoTipo(url) {
      let sedes = this.selectedSedes.map((el) => el.idempresa + '-' + el.value);
      let subAreas = this.selectedSubAreas.map((el) => el.value.replace('&', '%26'));
      let cuentasMayores = this.selectedCuentasMayores.map((el) => el.value.replace('&', '%26'));
      sedes = sedes.length == this.itemsSedes.length ? '' : sedes.toString();
      subAreas = subAreas.length == this.itemsSubAreas.length ? '' : subAreas.toString();
      cuentasMayores = cuentasMayores.length == this.itemsCuentasMayores.length ? '' : cuentasMayores.toString();
      const res = await this.service.get().execResource(url, {
        idtipoppto: this.selectedTipoPresupuesto.value,
        empresasede: sedes,
        idsubarea: subAreas,
        idcuentamayor: cuentasMayores,
        periodomin: this.periodoInicio,
        periodomax: this.periodoFin
      });
      return res;
    },
    async calcularPosFiltro() {
      console.log('scroll');
      let sidebarExpand = this.$store.getters.SidebarExpand;
      if (window.document.getElementById('dividerfiltertop').getBoundingClientRect().top < 65) {
        console.log('entraaa');
        if (sidebarExpand) {
          this.classheaderfilters = 'header-filters-expand';
          this.classfilters = 'filters-expand';
        } else {
          this.classheaderfilters = 'header-filters-not-expand';
          this.classfilters = 'filters-not-expand';
        }
      } else {
        this.classheaderfilters = '';
        this.classfilters = '';
      }
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.calcularPosFiltro);
  },
  async created() {
    this.service = this.$httpService(new DashboardService(), this);
    await this.initialize();
  }
};
</script>

<style lang="scss">
.expansion-panel-icon {
  order: 0;
  margin-right: 0.5rem;
}

.expansion-panel-header {
  order: 1;
}

.totales-expansion-panels .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0px;
}

.totales-celda-left {
  background-color: #f6f6f6;
  border-top-left-radius: 10px;
}

.totales-celda-right {
  background-color: #f6f6f6;
  border-top-right-radius: 10px;
}

.totales-expansion-panels li {
  list-style: none;
  margin-bottom: 4px;
  background-color: #f0f0f0;
  padding: 4px;
  padding-left: 16px;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer !important;
}

.totales-expansion-panels ul span {
  min-height: 10px;
  display: block;
}

.v-expansion-panel-content__wrap {
  padding: 0 0 24px 16px !important;
}

.v-expansion-panel-header {
  min-height: 0px;
}

.totales-expansion-panels .v-expansion-panel::before {
  box-shadow: none !important;
}
/*.header-filters-expand .v-card,
.header-filters-not-expand .v-card {
  background-color: #fafafa;
}*/
</style>
